import React, { useEffect, useState } from "react";
import "../Styles/Global.css";
// @ts-ignore
import FeedBackView from "../Common/FeedbackView.tsx";
// @ts-ignore
import ServiceClient from "../Services/ServiceClient.tsx";
// @ts-ignore
import CommonModal from "../Common/CommonModal.tsx";

const Feedback = () => {
  const serviceClient = new ServiceClient();
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);

  const getData = async () => {
    try {
      const response = await serviceClient.getFeedback();
      setError(
        !response ? "500 Server Error. Oops, Something went wrong !" : null
      );
      setData(response || []);
    } catch (error) {
      setError("500 Server Error. Oops, Something went wrong !");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="home-package-margin">
        <h2>Our Visitors FeedBack</h2>
        <div className="container-fluid card-component-margin">
          <div className="scrolling-wrapper row flex-row flex-nowrap mt-2 pb-4">
            {!error ? (
              data?.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-4 col-sm-12 col-12"
                    key={index}
                  >
                    <div>
                      <FeedBackView
                        Name={item.Name}
                        Country={item.Country}
                        Comment={item?.Comment}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </div>
        </div>

      <CommonModal
        showModal={showModal}
        setShowModal={setShowModal}
        body={selectedData}
        title={"Customer Reviews"}
      />
    </div>
  );
};

export default Feedback;

import React, { useState } from "react";
// @ts-ignore
import TitleBar from "../Common/TitleBar.tsx";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
// @ts-ignore
import CommonBackground from "../Common/CommonBackground.tsx";
// @ts-ignore
import ServiceClient from "../Services/ServiceClient.tsx";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const ContactUs = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const serviceClient = new ServiceClient();

  const submitData = async (e) => {
    e.preventDefault();
    const regex = /^\s*$/;
    const variables = {
      name: data?.name || "",
      email: data?.email || "",
      country: data?.country || "",
      address: data?.address || "",
      contact: data?.contact || "",
      message: data?.message || "",
    };

    if (
      regex.test(variables.name) ||
      regex.test(variables.email) ||
      regex.test(variables.country) ||
      regex.test(variables.address) ||
      regex.test(variables.contact) ||
      regex.test(variables.message)
    ) {
      toast.error("Fields cannot be empty");
      return;
    }

    setLoading(true);

    try {
      await serviceClient.createContact(variables);
      await serviceClient.sendEmail(variables);
      setLoading(false);
      toast.success("Successfully sent Contact Information");
    } catch (error) {
      setLoading(false);
      toast.error("Send Contact Information Failed");
    }
  };

  const feedbackSubmitData = async (e) => {
    e.preventDefault();
    const regex = /^\s*$/;
    const variables = {
      Name: data?.Name || "",
      Country: data?.Country || "",
      Comment: data?.Comment || "",
    };

    if (
      regex.test(variables.Name) ||
      regex.test(variables.Country) ||
      regex.test(variables.Comment)
    ) {
      toast.error("Fields cannot be empty");
      return;
    }

    setLoading(true);

    try {
      await serviceClient.createFeedback(variables);
      setLoading(false);
      toast.success("Successfully sent your Feedback");
    } catch (error) {
      setLoading(false);
      toast.error("Send Failed");
    }
  };

  return (
    <div id="contact">
      <CommonBackground
        ImgUrl={"/Assets/aerial-view-beautiful-tropical-beach.jpg"}
        Title={"Contact Us"}
        Description={""}
      />
      
      <TitleBar Title={"Contact Us"} />

      <div className="card-view-container">
        <div className="row ">
          <div className="col-lg-5 col-md-12 col-sm-12 mt-4 d-flex justify-content-center">
            <div className="contact-box">
              <div className="mt-2">
                <h4>Get in touch</h4>
                <br />
                <p>
                  Use our contact form for all information requests or contact
                  us directly using the contact information below.
                  <br />
                  <br />
                  Feel free to get in touch with us via email or phone
                </p>
              </div>

              <div className="mt-4 contact-box-bar"></div>

              <div className="contact-box-icons justify-content-center">
                {/* <LocationOnIcon /> */}
                {/* <span className="contact-icon-text-align">
                  &nbsp; Address: 500 CrossPoint Parkway Getzville, NY 14068
                </span> */}
                <br />
                <PhoneInTalkIcon />
                <span className="contact-icon-text-align">
                  &nbsp; +94 7 555 777 16
                </span>
                <br />
                <span className="contact-icon-text-align">
                  &nbsp; +94 7 784 572 25
                </span>
                <br />
                <br />
                <AlternateEmailIcon />
                <span className="contact-icon-text-align">
                  &nbsp; travelingreenisland@gmail.com
                </span>
              </div>

              <div className="mt-4 contact-box-bar"></div>

              <div className="review-box-icons justify-content-center">
                <h4>Add Your Comments</h4>
                <div>
                <input
                  className="review-box-form"
                  type="text"
                  value={data?.Name}
                  name="review_name"
                  placeholder="Name :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      Name: event.target.value,
                    })
                  }
                />
                <input
                  className="review-box-form"
                  type="text"
                  value={data?.Country}
                  name="review_country"
                  placeholder="Country or HomeTown :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      Country: event.target.value,
                    })
                  }
                />
                <input
                  className="review-box-form-area"
                  type="textarea"
                  value={data?.Comment}
                  name="review_message"
                  placeholder="Write your comment :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      Comment: event.target.value,
                    })
                  }
                />
                <Box sx={{ m: 1, position: "relative" }}>
                  <button
                    type="submit"
                    value="save"
                    disabled={loading}
                    className={
                      loading
                        ? "contact-box-button-loading"
                        : "contact-box-button"
                    }
                    onClick={feedbackSubmitData}
                  >
                    Submit my Comment
                  </button>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      thickness={6}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "10%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 mt-4 d-flex justify-content-center">
            <div className="image-container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                  <b>Scan to contact us on WeChat</b>
                  <a href="#">
                    <img
                      src="./Assets/VCHAT.jpg"
                      alt="Logo"
                      style={{ width: "200px", height: "auto" }}
                    />
                  </a>
                </div>
                <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                  <b>Scan to contact us on Whatsapp</b>
                  <br/>
                  <a href="#">
                    <img
                      src="./Assets/WACHAT.jpg"
                      alt="Logo"
                      style={{ width: "200px", height: "auto" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 mt-4 d-flex justify-content-center">
            <div className="contact-box">
              <div className="mt-2">
                <h4 className="text-center">We will get back to you asap</h4>
              </div>

              <div>
                <input
                  className="contact-box-form"
                  type="text"
                  value={data?.name}
                  name="from_name"
                  placeholder="Name :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      name: event.target.value,
                    })
                  }
                />
                <input
                  className="contact-box-form"
                  type="email"
                  value={data?.email}
                  name="user_email"
                  placeholder="Email :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      email: event.target.value,
                    })
                  }
                />
                <input
                  className="contact-box-form"
                  type="text"
                  value={data?.country}
                  name="country"
                  placeholder="Country :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      country: event.target.value,
                    })
                  }
                />
                <input
                  className="contact-box-form"
                  type="text"
                  value={data?.address}
                  name="address"
                  placeholder="Address :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      address: event.target.value,
                    })
                  }
                />
                <input
                  className="contact-box-form"
                  type="number"
                  value={data?.contact}
                  name="contact"
                  placeholder="Contact :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      contact: event.target.value,
                    })
                  }
                />
                <input
                  className="contact-box-form-area"
                  type="textarea"
                  value={data?.message}
                  name="user_message"
                  placeholder="Enter your message :"
                  required={true}
                  onChange={(event) =>
                    setData({
                      ...data,
                      message: event.target.value,
                    })
                  }
                />
                <Box sx={{ m: 1, position: "relative" }}>
                  <button
                    type="submit"
                    value="send"
                    disabled={loading}
                    className={
                      loading
                        ? "contact-box-button-loading"
                        : "contact-box-button"
                    }
                    onClick={submitData}
                  >
                    Plan Your Trip
                  </button>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      thickness={6}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "10%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                {/* <button
                  type="submit"
                  value="send"
                  className="contact-box-button"
                  onClick={submitData}
                >
                  Plan Your Trip
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

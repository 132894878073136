import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import { NavHashLink as Links } from "react-router-hash-link";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const NavBar = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [iconColor, setIconColor] = useState("black");
  const [scrolling, setScrolling] = useState(false);

  const handleClick = () => {
    if (isClicked) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
      setIconColor("white");
    } else {
      setIconColor("black");
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle: React.CSSProperties = {
    background: scrolling
      ? `#051A27`
      : isClicked
      ? `#051A27`
      : `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 16%, rgba(255,255,255,1) 27%, rgba(255,255,255,0.9333858543417367) 48%, rgba(255,255,255,0.7961309523809523) 63%, rgba(255,255,255,0) 100%)`,
    width: `100%`,
    height: "90px",
    position: `fixed`,
    color: scrolling ? "white" : isClicked ? `white` : `black`,
    zIndex: +1,
  };

  const navLink: React.CSSProperties = {
    color: scrolling ? "white" : isClicked ? `white` : `black`,
    fontWeight: `500`,
    fontFamily: `Comic Sans MS`,
    transition: "0.2s ease-in-out",
  };

  const mediaQuery: any = "(max-width: 768px)";
  const mobilenavbar: React.CSSProperties = {
    [mediaQuery]: {
      display: "flex",
      flexDirection: "column",
      background: scrolling ? `#051A27` : isClicked ? `#051A27` : `#ffffff`,
      width: `200px`,
      position: `fixed`,
      color: scrolling ? "black" : "white",
      zIndex: +1,
    },
  };

  if (window.matchMedia(mediaQuery).matches) {
    mobilenavbar.background = scrolling
      ? `#051A27`
      : isClicked
      ? `#051A27`
      : `#ffffff`;
  }

  return (
    <div>
      <nav style={navbarStyle}>
        <a
          className={isClicked ? "navbar-brand-none" : "navbar-brand"}
        >
          <img src="./Assets/Logo.png" alt="Logo" />
        </a>
        <div>
          <ul
            id="navbar"
            className={isClicked ? "navbar active" : "navbar navbar-hide-ul-box"}
            style={mobilenavbar}
          >
            <li className="nav-item">
              <Links
                className="nav-link"
                style={navLink}
                smooth={true}
                to={"/#home"}
                onClick={() => setIsClicked(false)}
              >
                Home
              </Links>
            </li>

            <li className="nav-item">
              <Links
                className="nav-link"
                style={navLink}
                smooth={true}
                to={"/#about"}
                onClick={() => setIsClicked(false)}
              >
                About
              </Links>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                style={navLink}
                to={"/packages"}
                onClick={() => setIsClicked(false)}
              >
                Packages
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                style={navLink}
                to={"/gallery"}
                onClick={() => setIsClicked(false)}
              >
                Gallery
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                style={navLink}
                to={"/contact"}
                onClick={() => setIsClicked(false)}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div id="mobile" className="mobile-view-index" onClick={handleClick}>
          {!isClicked ? (
            <MenuIcon sx={{ color: iconColor }} />
          ) : (
            <CloseIcon sx={{ color: "white" }} />
          )}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;

// @ts-ignore
import { DataMethodInterface } from "../Interfaces/DataMethodInterface.tsx";

export class ServiceHandler implements DataMethodInterface {
    createContact(variables: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    sendEmail(variables: any): Promise<any> {
        throw new Error('Method Not Implemented');
    }
    getPackages(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    getGalleryData(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    feedbackSubmitData(variables: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
}

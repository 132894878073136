import React from 'react';
import '../Styles/Global.css';
// @ts-ignore
import TitleBar from '../Common/TitleBar.tsx';

const AboutUs = () => {
    return (
        <div id='about'>
            <TitleBar Title={"About Us"}/>
            <div className='row row-position'>
                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                    <div className='div-about-description'>
                        <p>
                            <span className='about-awe-title'>Awesome</span> <br />
                            <span className='about-sri-title'>Sri Lanka</span>
                        </p>
                        <p>
                        Sri Lanka, the Pearl of the Indian Ocean, beckons with its exquisite blend of natural beauty, rich heritage, and warm hospitality. Nestled in the heart of South Asia, this enchanting island nation is a tapestry of lush jungles, pristine beaches, and ancient temples that whisper tales of a bygone era.
                        Discover the wonders of Sri Lanka as you explore its diverse landscapes, from the misty tea plantations of the central highlands to the golden sands of its coastal shores. Immerse yourself in the vibrant culture, savor the flavors of aromatic cuisine, and witness the harmonious coexistence of tradition and modernity.
                        Whether you seek tranquil moments in serene temples, thrilling adventures in untamed wilderness, or simply the joy of unwinding on palm-fringed beaches, Sri Lanka invites you to embark on a journey of wonder and discovery. Join us in celebrating the splendor of this resplendent island on our About Us page and let us inspire your next unforgettable adventure.

                        </p>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                    <div className='about-side-img'>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
import React, { useState } from "react";
import "../Styles/Admin.css";
import Database from "../Services/DBConnection.tsx";
import { toast } from "react-toastify";

const AdminPortal = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [progress, setProgress] = useState<any>(null);

  const onFileChange = (event) => {
    setSelectedFile(event);
  };

  const UploadFile = () => {
    setProgress("Uploading...");
    const storageRef = Database.Firebase.storage().ref();
    const uploadFile = storageRef
      .child(selectedFile.target.files[0].name)
      .put(selectedFile.target.files[0]);

    uploadFile.on(
      "state_changed",
      null,
      (error) => {
        console.log(error);
      },
      () => {
        uploadFile.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          const data = {
            ImageUrl: downloadUrl,
          };

          Database.FireStore.collection("gallery")
            .doc()
            .set(data)
            .then(() => {
              setProgress("Uploaded");
              toast.success("Image Upload Succeeded");
              setProgress("");
            })
            .catch(() => {
              setProgress("Failed");
              toast.success("Image upload Failed. Please contact Admin");
            });
        });
      }
    );
  };
  return (
    <div>
      <br />
      <h3>AdminPortal</h3>
      <br />
      <input
        type="file"
        className="upload-file-box"
        placeholder="Upload your image"
        onChange={(event) => onFileChange(event)}
      ></input>
      <br />
      <br />
      {progress}
      <br />
      <br />
      <button className="upload-button" onClick={UploadFile}>
        Upload Image
      </button>
    </div>
  );
};

export default AdminPortal;

import React from 'react'
import '../Styles/Global.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const FeedBackView = ({Name , Country, Comment}) => {
    return (
        <Card className='card-2'>
            <CardActionArea>
                <CardContent>
                    <Typography className='lead text-muted font-weight-bold card-font-review' variant="body2" component="div" color="black">
                        "{Comment}"
                    </Typography>
                    <br></br>
                    <Typography className='font-italic font-weight-bold card-font-review' variant="body2" component="div" color="black">
                        {Country}
                    </Typography>
                    <Typography className='font-italic font-weight-bold card-font-review' variant="body2" component="div" color="black">
                        --- {Name} ---
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
        
    );
}

export default FeedBackView;
// @ts-ignore
import { ServiceHandler } from "./ServiceHandler.tsx";
import emailjs from "@emailjs/browser";
import config from "../Config/config.json";
// @ts-ignore
import Firebase from "./DBConnection.tsx";

export default class ServiceClient extends ServiceHandler {
  async sendEmail(variables: any): Promise<any> {
    try {
      await emailjs.send(
        config.emailService.SERVICE_ID,
        config.emailService.TEMPLATE_ID,
        variables,
        config.emailService.PUBLIC_KEY
      );
    } catch (error) {
      throw error;
    }
  }

  async getPackages(): Promise<any> {
    try {
      const snap = await Firebase.FireStore.collection("packages").get();
      const packagesData = snap.docs.map((doc) => {
        return {
          DocId: doc.id,
          ImageUrl: doc.data().ImageUrl,
          Name: doc.data().Name,
          Description: doc.data().Description,
          Days: doc.data().Days,
        };
      });

      return packagesData;
    } catch (error) {
      throw error;
    }
  }

  async getGalleryData(): Promise<any> {
    try {
      const snap = await Firebase.FireStore.collection("gallery").get();
      const galleryData = snap.docs.map((doc) => {
        return {
          DocId: doc.id,
          ImageUrl: doc.data().ImageUrl,
        };
      });
      return galleryData;
    } catch (error) {
      throw error;
    }
  }

  async createContact(variables: any): Promise<any> {
    try {
      await Firebase.FireStore.collection("contact").doc().set(variables);
    } catch (error) {
      throw error;
    }
  }

  async getFeedback(): Promise<any> {
    try {
      const snap = await Firebase.FireStore.collection("feedback").get();
      const feedbackData = snap.docs.map((doc) => {
        return {
          DocId: doc.id,
          Name: doc.data().Name,
          Country: doc.data().Country,
          Comment: doc.data().Comment,
        };
      });

      return feedbackData;
    } catch (error) {
      throw error;
    }
  }

  async createFeedback(variables: any): Promise<any> {
    try {
      await Firebase.FireStore.collection("feedback").doc().set(variables);
    } catch (error) {
      throw error;
    }
  }
}

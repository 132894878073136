import React from "react";
import "../Styles/Global.css";

const CommonBackground = ({ ImgUrl, Title, Description }) => {
  const packageBgStyle: React.CSSProperties = {
    background: `url(${ImgUrl}) no-repeat center`,
    height: "70vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    zIndex: -1,
    position: "relative",
    top: 0,
  };

  return (
      <div style={packageBgStyle}>
        <p className="p-package-title">
          {Title}
          <br />
          <span className="p-package-title-sub">{Description}</span>
        </p>
      </div>
  );
};

export default CommonBackground;

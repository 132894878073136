import "./App.css";
import NavBar from "./Header/NavBar.tsx";
import Footer from "./Footer/Footer.tsx";
import Home from "./Components/Home.tsx";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Package from "./Components/Package.tsx";
import ContactUs from "./Components/ContactUs.tsx";
import Gallery from "./Components/Gallery.tsx";
import Toast from "./Common/Toast.tsx";
import AdminPortal from "./Components/AdminPortal.tsx";

function App() {
  const currentPath = window.location.pathname;

  // console.log(currentPath);

  return (
    <div className="App">
      <BrowserRouter>
        {currentPath === "/admin" ? (
          <>
            <Toast />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/packages" element={<Package />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/admin" element={<AdminPortal />} />
            </Routes>
          </>
        ) : (
          <>
            <NavBar />
            <Toast />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/packages" element={<Package />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/admin" element={<AdminPortal />} />
            </Routes>
            <Footer />
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;

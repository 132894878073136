import React, { useEffect, useState } from "react";
import "../Styles/Global.css";
// @ts-ignore
import CardView from "../Common/CardView.tsx";
// @ts-ignore
import ServiceClient from "../Services/ServiceClient.tsx";
// @ts-ignore
import CommonModal from "../Common/CommonModal.tsx";

const HomePackages = () => {
  const serviceClient = new ServiceClient();
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);

  const getData = async () => {
    try {
      const response = await serviceClient.getPackages();
      setError(
        !response ? "500 Server Error. Oops, Something went wrong !" : null
      );
      setData(response || []);
    } catch (error) {
      setError("500 Server Error. Oops, Something went wrong !");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="home-package-margin">
      <div className="home-package-img">
        <p className="p-home-package-title">
          Plan Your Trip <br /> To Explore the Island Sri Lanka
        </p>

        <div className="container-fluid card-component-margin">
          <div className="scrolling-wrapper row flex-row flex-nowrap mt-4 pb-4 pt-2">
            {!error ? (
              data?.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-4 col-sm-12 col-12"
                    key={index}
                  >
                    <div
                      className="card card-block"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedData(item);
                      }}
                    >
                      <CardView
                        ImgUrl={item.ImageUrl}
                        Title={item.Name}
                        SubTitle={item?.Days}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
      <CommonModal
        showModal={showModal}
        setShowModal={setShowModal}
        body={selectedData}
        title={"Package Details"}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default HomePackages;

import React from 'react';
import '../Styles/Global.css';
// @ts-ignore
import AboutUs from './AboutUs.tsx';
// @ts-ignore
import HomePackages from './HomePackages.tsx';
// @ts-ignore
import { Link, useNavigate } from 'react-router-dom';
import { constants } from 'buffer';
import Feedback from './Feedback.tsx';

const Home = () => {

    const navigate = useNavigate();

    const onClick = () => {
        navigate('/packages');
        console.log("sample")
    }
    return (
        <>
            <div id='home' className='home-bg-img'>
                <div>
                    <img className='home-sub-bg-img' src='./Assets/Visit-Sri-Lanka.png' alt='bg'></img>
                    <p className='p-title'>Taking you to the <br /> best places</p>
                    <button className='button mr-2' onClick={onClick}>Plan Your Trip</button>
                </div>
            </div>
            <AboutUs></AboutUs>
            <Feedback></Feedback>
            <HomePackages></HomePackages>
        </>
    );
}

export default Home;
import React, { useEffect, useState } from "react";
// @ts-ignore
import TitleBar from "../Common/TitleBar.tsx";
// @ts-ignore
import CardView from "../Common/CardView.tsx";
// @ts-ignore
import CommonBackground from "../Common/CommonBackground.tsx";
// @ts-ignore
import ServiceClient from "../Services/ServiceClient.tsx";
// @ts-ignore
import CommonModal from "../Common/CommonModal.tsx";

const Package = () => {
  const serviceClient = new ServiceClient();
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);

  const getData = async () => {
    try {
      const response = await serviceClient.getPackages();
      setError(
        !response ? "500 Server Error. Oops, Something went wrong !" : null
      );
      setData(response || []);
    } catch (error) {
      setError("500 Server Error. Oops, Something went wrong !");
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const packageBgStyle: React.CSSProperties = {
    background: `url(/Assets/beautiful-nature-tropical-beach-sea-with-coconut-palm-tree-paradise-island.jpg) no-repeat center`,
    height: "70vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    zIndex: -1,
    position: "relative",
    top: 0,
  };
  
  return (
    <div>
      <div style={packageBgStyle}>
        <p className="p-package-title-only">
        Packages
        </p>
        <p className="p-package-sub-only">Airport Pick-Up / Drop / Taxi Service / Long Tours / One Day Tours / City Tours etc.. with reasonable Prize</p>
        
      </div>
      {/* <CommonBackground
        ImgUrl={"/Assets/aerial-view-beautiful-tropical-beach.jpg"}
        Title={"Packages"}
        Description={"Airport Pick-Up / Drop / Taxi Service / Long Tours / One Day Tours / City Tours etc.. with reasonable Prize"}
      /> */}

      <TitleBar Title={"Packages"} />

      <div className="card-view-container">
        <div className="row">
          {!error ? (
            data?.map((item , index) => {
              return (
                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                  <div
                    className="card card-block"
                    onClick={() => {
                      setShowModal(true);
                      setSelectedData(item);
                    }}
                  >
                    <CardView
                      ImgUrl={item?.ImageUrl}
                      Title={item?.Name}
                      SubTitle={item?.Days}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
      </div>

      <CommonModal
        showModal={showModal}
        setShowModal={setShowModal}
        body={selectedData}
        title={"Package Details"}
      />
    </div>
  );
};

export default Package;

import React from 'react'
import '../Styles/Global.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const CardView = ({ImgUrl, Title , SubTitle}) => {
    return (
        <Card className='card-1'>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="240"
                    image={ImgUrl}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography className='card-font' variant="body2" component="div" color="white">
                        {Title}
                    </Typography>
                    <Typography className='card-font' variant="body2" component="div" color="white">
                        {SubTitle}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default CardView;
import React from "react";
import "./Footer.css";
// @ts-ignore
import fb from "../Assets/facebook.png";
// @ts-ignore
import twitter from "../Assets/instagram.png";
// @ts-ignore
import tripAdviser from "../Assets/tripad.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Whatsapp from "@mui/icons-material/WhatsApp";

const Footer = () => {
  return (
    <div className="footer">
      <div className="sb_footer section_padding">
        <div className="sb_footer-links">
          <div className="sb_footer-links-div">
            <a href="#">
              <img
                src="./Assets/Logo.png"
                alt="Logo"
                style={{ width: "150px", height: "150px" }}
              />
            </a>
          </div>
          <div className="sb_footer-links-div">
            <h4></h4>
            {/* <br></br> */}
            <br></br>
            {/* <p><LocationOnIcon />&nbsp; Address: 500 Getzville, NY 14068</p> */}
            <p>
              <PhoneInTalkIcon /> &nbsp;
              <a href="">+94 75 5577 716 </a> / <a href=""> +94 77 8457 225</a>
            </p>
            <p>
              <AlternateEmailIcon />
              &nbsp; travelingreenisland@gmail.com
            </p>
          </div>
          <div className="sb_footer-links-div">
            <h4></h4>
            <br></br>
            <div className="socialmedia">
              {/* <a href=""><FacebookIcon /></a>
                        <a href=""><TwitterIcon /></a> */}
              <a
                href="https://www.facebook.com/profile.php?id=100063796560535&mibextid=ZbWKwL"
                target="_blank"
              >
                <FacebookIcon sx={{ fontSize: 40 }} className="icon-sizing" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100063796560535&mibextid=ZbWKwL"
                target="_blank"
              >
                <InstagramIcon sx={{ fontSize: 40 }} className="icon-sizing" />
              </a>
              <a
                href="https://wa.me/message/GOUYOX6LIKIZN1?src=qr"
                target="_blank"
              >
                <Whatsapp sx={{ fontSize: 40 }} className="icon-sizing" />
              </a>
              <a
                href="https://www.tripadvisor.com/Attraction_Review-g1500185-d16860478-Reviews-Travel_in_Green_Island-Katunayake_Negombo_Western_Province.html"
                target="_blank"
              >
                <img src={tripAdviser} alt="" className="icon-sizing"/>
              </a>
            </div>
          </div>
        </div>

        <div className="sb_footer-below">
          <div className="sb_footer-copywright">
            <p>
              @{new Date().getFullYear()} All right reserved @ Travel in Green
              Island | Design & Developed by Future Code.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import firebase from 'firebase';
import config from '../Config/config.json';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: config.firebaseConfig.apiKey,
    authDomain: config.firebaseConfig.authDomain,
    projectId: config.firebaseConfig.projectId,
    storageBucket: config.firebaseConfig.storageBucket,
    messagingSenderId: config.firebaseConfig.messagingSenderId,
    appId: config.firebaseConfig.appId,
    measurementId: config.firebaseConfig.measurementId
};

// Initialize Firebase
const Firebase = firebase.initializeApp(firebaseConfig);

// Initialize Database
const FireStore = Firebase.firestore();

// Export database as default
export default { FireStore, Firebase};
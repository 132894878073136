import React, { useEffect, useState } from "react";
import "../Styles/Global.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
// @ts-ignore
import TitleBar from "../Common/TitleBar.tsx";
// @ts-ignore
import CommonBackground from "../Common/CommonBackground.tsx";
// @ts-ignore
import ServiceClient from "../Services/ServiceClient.tsx";

const Gallery = () => {
  const columnsCountBreakPoints = { 350: 1, 750: 2, 900: 3 };
  const serviceClient = new ServiceClient();
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>(null);

  const getData = async () => {
    try {
      const response = await serviceClient.getGalleryData();
      setError(
        !response ? "500 Server Error. Oops, Something went wrong !" : null
      );
      setData(response || []);
    } catch (error) {
      setError("500 Server Error. Oops, Something went wrong !");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="gallery">
      <CommonBackground
        ImgUrl={"/Assets/sunrise-morning-mist-phu-lang-ka-phayao-thailand.jpg"}
        Title={"Gallery"}
        Description={""}
      />

      <TitleBar Title={"Gallery"} />

      <div className="card-view-container">
        {!error ? (
          <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
            {/* // @ts-ignore */}
            <Masonry gutter={10}>
              {data.map((item) => (
                <img key={item.DocId} src={item.ImageUrl} alt="img" />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;

import React from 'react'
import '../Styles/Global.css';


const TitleBar = ({Title}) => {
    return (
        <nav className="navbar navbar-section">
            <div className='container'>
                <span className="navbar-brand navbar-brand-text">{Title}</span>
                <img src="./Assets/Flag.png" alt="Logo" className="navbar-logo"></img>
            </div>
        </nav>
    );
}

export default TitleBar;
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const CommonModal = ({ title, body, showModal, setShowModal }) => {
  const navigate = useNavigate();
  const handleClose = () => setShowModal(false);
  const handleClick = () => navigate('/contact');

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "#051A27",
    border: "none",
    color: "white",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ maxHeight: "80vh", overflowY: "auto" }}>
        <CloseIcon
          edge="end"
          // @ts-ignore
          color="white"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <b>{title}</b>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="row">
            <div className="col-lg-3">
              <img src={body?.ImageUrl} className="modal-img"></img>
            </div>
            <div className="col-lg-9 modal-body-margin">
              <div className="row down-desc-title">
                <div className="col-lg-2 col-md-3 col-sm-3 col-3">
                  <b>Name</b>
                </div>
                <div className="col-lg-1 col-md-3 col-sm-3 col-1">
                  <b>:</b>
                </div>
                <div className="col-lg-9 col-md-4 col-sm-6 col-12 down-desc">
                  {body?.Name}
                </div>
              </div>

              <div className="row down-desc-title">
                <div className="col-lg-2 col-md-3 col-sm-3 col-3">
                  <b>Type</b>
                </div>
                <div className="col-lg-1 col-md-3 col-sm-3 col-1">
                  <b>:</b>
                </div>
                <div className="col-lg-9 col-md-4 col-sm-6 col-12 down-desc">
                  {body?.Days}
                </div>
              </div>

              <div className="row down-desc-title">
                <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                  <b>Description</b>
                </div>
                <div className="col-lg-1 col-md-3 col-sm-3 col-2">
                  <b>:</b>
                </div>
                <div className="col-lg-9 col-md-4 col-sm-6 col-12 down-desc">
                  {body?.Description}
                </div>
              </div>
            </div>
          </div>
        </Typography>
        <button className="btn-modal" onClick={handleClick}>Plan Your Trip</button>
      </Box>
    </Modal>
  );
};

export default CommonModal;
